import { FaRegHandPointer } from 'react-icons/fa';

export const values = [
    {
        id: 1,
        icon: <FaRegHandPointer/>,
        title: "Accessibility",
        desc: "We make programming education accessible to everyone through live, online classes."
    },
    {
        id: 2,
        icon: <FaRegHandPointer/>,
        title: "Engagement",
        desc: "We create an enjoyable and engaging learning experience for individuals to keep them motivated in their programming journey."
    },
    {
        id: 3,
        icon: <FaRegHandPointer/>,
        title: "Experience",
        desc: "We provide comprehensive and hands-on courses that equip individuals with practical experience in coding."
    },
    {
        id: 4,
        icon: <FaRegHandPointer/>,
        title: "Expertise",
        desc: "We impart expert knowledge and real-world industry experience through its experienced professionals, to ensure students receive the best possible education."
    }
]