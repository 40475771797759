import { SiCodio, SiJava } from 'react-icons/si';

export const courses = [
    {
        id: 1,
        icon: <SiCodio/>,
        title: "C Fundamentals for Beginners",
        info: "Start your coding journey with confidence. Learn the basics of C programming with our beginner-friendly course.",
        path: "/courses/1"
    },
    {
        id: 2,
        icon: <SiJava/>,
        title: "Mastering Java: A Guide for Beginners",
        info: "Discover the power of Java and become a master coder with our comprehensive and easy-to-follow guide, designed specifically for beginners. Start your programming journey today!",
        path: "/courses/2"
    },
    {
        id: 3,
        icon: <SiCodio/>,
        title: "C Programming: Advanced Techniques and Concepts",
        info: "Unlock your coding potential with our advanced C programming course. Learn advanced techniques and concepts to take your skills to the next level.",
        path: "/courses/3"
    },
    {
        id: 4,
        icon: <SiJava/>,
        title: "Java Mastery: Advanced Techniques & Best Practices",
        info: "Take your Java skills to the next level with our advanced course in Java Mastery. Dive into best practices and master the latest techniques.",
        path: "/courses/4"
    }
]