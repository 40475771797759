import { Routes, Route, HashRouter } from 'react-router-dom';

import Navbar from './components/navbar/Navbar';
import AboutUs from './routes/about-us/AboutUs';
import BookOnline from './routes/book-online/BookOnline';
import Contact from './routes/contact/Contact';
import Courses from './routes/courses/Courses';
import Home from './routes/home/Home';
import Teachers from './routes/teachers/Teachers';
import FAQ from './routes/faq/FAQ';
import NotFound from './routes/not-found/NotFound';
import Footer from './components/common/footer/Footer';
import ScrollToTop from './components/common/ScrollTop/ScrollToTop';
import CourseDetails from './routes/courses/course-details/CourseDetails';
import ScrollToTopOnNav from './components/navbar/ScrollToTopOnNav';

const App = () => {
  return (
    <HashRouter>
      <Navbar/>
      <Routes>
        <Route index element={<Home/>}/>
        {/* Main pages */}
        <Route path='courses' element={<Courses/>}/>
        <Route path='teachers' element={<Teachers/>}/>
        <Route path='about-us' element={<AboutUs/>}/>
        <Route path='book-online' element={<BookOnline/>}/>
        <Route path='contact' element={<Contact/>}/>
        <Route path='faq' element={<FAQ/>}/>
        <Route path='blog' element={<NotFound pageTitle="The Blog feature is coming soon"/>}/>
        <Route path='forum' element={<NotFound pageTitle="The Forum feature is coming soon"/>}/>
        <Route path='events' element={<NotFound pageTitle="The Events feature is coming soon"/>}/>
        <Route path='*' element={<NotFound pageTitle="Page Not Found"/>}/>

        {/* Sub pages */}
        <Route path='courses/:id' element={<CourseDetails/>}/>
      </Routes>
      <ScrollToTop/>
      <Footer/>
      <ScrollToTopOnNav/>
    </HashRouter>
  );
}

export default App;