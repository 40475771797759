import { Link } from "react-router-dom";
import Logo from '../../../assets/footer/vertical-logo.png';
import { FaRegCopyright } from "react-icons/fa";
import Socials from "../socials/Socials";
import { footerData } from "../../../data/footer";
import './footer.css';

const Footer = () => {

    const { permalinks, insights, getInTouch } = footerData;

    return (
        <footer>
            <div className="container footer__container">
                <article>
                    <Link to="/" className="logo">
                        <img src={Logo} alt="Footer Logo" />
                    </Link>
                    <div className="footer__socials">
                        <Socials />
                    </div>
                </article>
                <article className="footer__menu">
                    <h4>Permalinks</h4>
                    {
                        permalinks.map(({ id, name, path }) => {
                            return <Link key={id} to={path}>{name}</Link>
                        })
                    }
                </article>
                <article className="footer__menu">
                    <h4>Insights</h4>
                    {
                        insights.map(({ id, name, path }) => {
                            return <Link key={id} to={path}>{name}</Link>
                        })
                    }
                </article>
                <article className="footer__menu">
                    <h4>Get In Touch</h4>
                    {
                        getInTouch.map(({ id, name, path }) => {
                            return <Link key={id} to={path}>{name}</Link>
                        })
                    }
                </article>
            </div>
            <div className="footer__copyright">
                <small>2023 Codebuddies Ltd. {<FaRegCopyright />} All Rights Reserved</small>
            </div>
        </footer>
    );
}

export default Footer;