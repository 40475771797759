
export const footerData = {
    permalinks: [
        {
            id: 1,
            name: "About",
            path: '/about-us'
        },
        {
            id: 2,
            name: "Courses",
            path: '/courses'
        },
        {
            id: 3,
            name: "Teachers",
            path: '/teachers'
        }
    ],

    insights: [
        {
            id: 1,
            name: "Blog",
            path: '/blog'
        },
        {
            id: 2,
            name: "Forum",
            path: '/forum'
        },
        {
            id: 3,
            name: "Events",
            path: '/events'
        },
        {
            id: 4,
            name: "FAQs",
            path: '/faq'
        }
    ],

    getInTouch: [
        {
            id: 1,
            name: "Contact",
            path: '/contact'
        },
        {
            id: 2,
            name: "Book Online",
            path: '/book-online'
        }
    ]
}