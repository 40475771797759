import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import Header from '../../components/common/Header';
import HeaderImage from '../../assets/contact/contact-us-header.jpg';
import { MdEmail } from 'react-icons/md';
import { BsMessenger } from 'react-icons/bs';
import { IoLogoWhatsapp } from 'react-icons/io';
import { FiCheckSquare } from 'react-icons/fi';
import { ContactData as data } from '../../data/contact/contact-data';
import ContactInfo from '../../components/contact/ContactInfo';
import ContactForm from '../../components/contact/ContactForm';
import './contact.css';

const Contact = () => {

    const [messageSent, setMessageSent] = useState(false);

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(data.emailjsServiceId, data.emailjsTemplateId, form.current, data.emailjsToken)
            .then((result) => {
                console.log(result.text);
                setMessageSent(true);
            }, (error) => {
                console.log(error.text);
                setMessageSent(false);
            });
    };

    return (
        <>
            <Header title="Get In Touch" image={HeaderImage}>
                Get in Touch with the Codebuddies Team - We look forward to connecting with you.
            </Header>
            <section className='contact'>
                <div className="container contact__container">
                    <div className="contact__wrapper">
                        <a href={"mailto:" + data.email} target="_blank" rel='noreferrer noopener'><MdEmail /></a>
                        <a href={data.messenger} target="_blank" rel='noreferrer noopener'><BsMessenger /></a>
                        <a href={data.whatsapp} target="_blank" rel='noreferrer noopener'><IoLogoWhatsapp /></a>
                    </div>
                </div>
            </section>
            <div className="line"></div>
            <div className="contact__form-container">
                <div className="form">
                    <ContactInfo
                        formInfoTitle={data.formInfoTitle}
                        formInfoText={data.formInfoText}
                        email={data.email}
                    />
                    <ContactForm
                        form={form}
                        sendEmail={sendEmail}
                        formTitle={data.formTitle}
                        fields={data.fields}
                    />
                </div>
                {
                    messageSent ?
                        <div className="message__delivered-text">
                            <FiCheckSquare />
                            <p>{data.messageDeliveredText}</p>
                        </div> : null
                }
            </div>
        </>
    );
}

export default Contact;