
const AboutUsSection = ({ sectionClass, containerClass, title, image, data, imageOnRight }) => {
    return (
        <section className={sectionClass}>
            <div className={containerClass}>

                {
                    imageOnRight ?
                        <>
                            <div className="about__section-content">
                                <h1>{title}</h1>
                                {
                                    data.map(({ id, text }) => {
                                        return (
                                            <p key={id}>{text}</p>
                                        );
                                    })
                                }
                            </div>
                            <div className="about__section-image">
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img src={`${process.env.PUBLIC_URL}/images/about-us/${image}`} alt={image} />
                            </div>
                        </> :
                        <>
                            <div className="about__section-image">
                                {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                                <img src={`${process.env.PUBLIC_URL}/images/about-us/${image}`} alt={image} />
                            </div>

                            <div className="about__section-content">
                                <h1>{title}</h1>
                                {
                                    data.map(({ id, text }) => {
                                        return (
                                            <p key={id}>{text}</p>
                                        );
                                    })
                                }
                            </div>
                        </>
                }

            </div>
        </section>
    )
}

export default AboutUsSection