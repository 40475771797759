import { FaQuestion } from "react-icons/fa";
import SectionHead from "./SectionHead";
import { faqs } from '../../data/home/faqs';
import FAQList from "../faq/FAQList";
import PrimaryButton from "../common/PrimaryButton";

const HomeFAQs = () => {

    const homeFaqs = faqs.slice(0, 4);

    return (
        <section className="faqs">
            <div className="container faqs__container">
                <SectionHead icon={<FaQuestion />} title="FAQs" />
                <FAQList faqs={homeFaqs} />
            </div>
            <div className="faq__container-btn">
                <PrimaryButton path='/faq' name='Show More' />
            </div>
        </section>
    );
}

export default HomeFAQs;