import './info-banner.css';

const InfoBanner = ({ path, linkedText, infoText, target }) => {
    return (
        <div className="info__banner">
            <div className="info__banner-content">
                <h3>{infoText}</h3>
                <a href={path} target={target} rel="noreferrer noopener">
                    <h3>&nbsp; {linkedText}</h3>
                </a>
            </div>
        </div>
    );
}

export default InfoBanner;