

export const faqs = [
    {
        id: 1,
        question: "What is Codebuddies?",
        answer: "Codebuddies is a live, online learning platform for individuals who want to learn programming languages like C and Java. We strive to make learning programming fun and engaging, helping our students to \"Code with Confidence\"."
    },
    {
        id: 2,
        question: "What programming languages does Codebuddies offer?",
        answer: "Codebuddies offers courses in C (Structural Approach) and Java (Object Oriented Approach) programming languages for the time being. But soon, it will come up with some other courses for Python, Javascript, Swfit (iOS), Kotlin (Android) etc."
    },
    {
        id: 3,
        question: "How does Codebuddies work?",
        answer: "Codebuddies provides live, online classes led by experienced instructors. Our classes are interactive and allow students to ask questions and engage with the instructor in real-time."
    },
    {
        id: 4,
        question: "Is Codebuddies only for beginners?",
        answer: "While Codebuddies is suitable for beginners, we also offer courses for individuals with intermediate or advanced programming knowledge."
    },
    {
        id: 5,
        question: "Can I learn programming at my own pace with Codebuddies?",
        answer: "Codebuddies is designed for individuals who prefer a structured learning environment with live, online classes. If you're looking for a self-paced learning experience, Codebuddies may not be the best fit for you."
    },
    {
        id: 6,
        question: "How much does Codebuddies cost?",
        answer: "The cost of Codebuddies courses depends on the length and level of the course you choose. We offer a variety of course options to suit different budgets and needs."
    },
    {
        id: 7,
        question: "Can I talk to the teacher(s) of the course before I buy?",
        answer: "Yes, we offer to book an appointment for new students who want to talk to our teacher(s) and see if our courses are a good fit for them."
    },
    {
        id: 8,
        question: "What makes Codebuddies different from other programming learning platforms?",
        answer: "Codebuddies provides a unique and interactive learning experience with live, online classes. Our instructors are experienced and passionate about teaching, and our platform is designed to make learning programming fun and engaging."
    },
    {
        id: 9,
        question: "Can I access Codebuddies from anywhere?",
        answer: "Yes, Codebuddies is accessible from anywhere with an internet connection."
    },
    {
        id: 10,
        question: "Are there any prerequisites for the courses offered on Codebuddies?",
        answer: "Some courses may have prerequisites, but this varies depending on the course. Be sure to check the course description before enrolling to see if there are any required skills or knowledge."
    },
    {
        id: 11,
        question: "How can I enroll in a course on Codebuddies?",
        answer: "To enroll in a course on Codebuddies, simply find the course you're interested in and click the \"Enroll\" button. Follow the prompts to complete your enrollment and start learning!"
    },
    {
        id: 12,
        question: "Is the appointment required before buying a course?",
        answer: "No, the appointment is not required before buying a course. However, it is highly recommended for individuals who want to make sure they are making the right decision and investing in a course that is right for them."
    },
]