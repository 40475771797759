import HeaderImage from '../../assets/teachers/teachers-header.jpg';
import Header from '../../components/common/Header';
import TeacherCard from '../../components/teachers/TeacherCard';
import { teachers } from '../../data/teachers/teachers';
import './teachers.css';

const Teachers = () => {
    return (
        <>
            <Header title="Meet Our Expert Instructors" image={HeaderImage}>
                Our team of knowledgeable and dedicated instructors are dedicated to helping you achieve your programming goals. Learn from the best in the industry and take your skills to the next level.
            </Header>

            <div className="teachers__container">
                {
                    teachers.map(({ id, name, image, occupation, description, email, linkedIn, twitter }) => {
                        return (
                            <TeacherCard
                                key={id}
                                name={name}
                                image={image}
                                occupation={occupation}
                                description={description}
                                email={email}
                                linkedIn={linkedIn}
                                twitter={twitter}
                            />
                        );
                    })
                }
            </div>
        </>
    );
}

export default Teachers;