import { HiOutlineMail } from "react-icons/hi";
import Socials from "../common/socials/Socials";

const ContactInfo = ({ formInfoTitle, formInfoText, email }) => {
    return (
        <div className="contact__info">
            <h3 className="title">{formInfoTitle}</h3>
            <p className="text">{formInfoText}</p>

            <div className="info">
                <div className="information">
                    <HiOutlineMail />
                    <p>{email}</p>
                </div>
            </div>

            <div className="contact__socials">
                <Socials />
            </div>
        </div>
    );
}

export default ContactInfo;