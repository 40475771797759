import Courses from '../../components/home/Courses';
import MainHeader from '../../components/home/MainHeader';
import Values from '../../components/home/Values';
import './home.css';
import HomeFAQs from '../../components/home/HomeFAQs';

const Home = () => {
    return (
        <>
            <MainHeader />
            <Courses />
            <Values />
            <HomeFAQs />
        </>
    );
}

export default Home;