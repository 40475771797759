
export const ContactData = {
    formInfoTitle: "Reach Out to Us ...",
    formInfoText: "Our Contact Us form makes it easy to reach out to our team, and we strive to respond to all inquiries in a timely manner. We value your input and are always looking for ways to improve the CodeBuddies experience.",
    email: "info@codebuddies.net",
    whatsapp: "https://wa.me/+4917659139920",
    messenger: "https://m.me/codebuddies.net",
    formTitle: "Send Us a Message",
    fields: [
        {
            id: 1,
            name: "Name",
            span: "Name",
            emailjsVar: "user_name",
            type: "text",
            required: true
        },
        {
            id: 2,
            name: "Email",
            span: "Email",
            emailjsVar: "user_email",
            type: "email",
            required: true
        },
        {
            id: 3,
            name: "Phone",
            span: "Phone",
            emailjsVar: "user_phone",
            type: "tel",
            required: false
        },
        {
            id: 4,
            name: "Message",
            span: "Message",
            emailjsVar: "message",
            type: "textarea",
            required: true
        }
    ],
    messageDeliveredText: "We have received your message! We will get back to you soon.",
    emailjsServiceId: "service_e8hgirp",
    emailjsTemplateId: "template_92rhwee",
    emailjsToken: "PkveUPoK1rnwfBYen"
}