import { SiCoderwall } from 'react-icons/si';
import SectionHead from './SectionHead';
import { courses } from '../../data/home/courses';
import Card from '../../ui/Card';
import { Link } from 'react-router-dom';
import { RiArrowRightSLine } from 'react-icons/ri';

/**
 * This is not a route/page. 
 * This is just a component to show in the home page.
 */
const Courses = () => {
  return (
    <section className="courses">
      <div className="container courses__container">
        <SectionHead icon={<SiCoderwall />} title="Courses" />
        <div className="courses__wrapper">
          {
            courses.map(({ id, icon, title, info, path }) => {
              return (
                <Card className="courses__course" key={id}>
                  <span>{icon}</span>
                  <h4>{title}</h4>
                  <small>{info}</small>
                  <Link to={path} className="btn sm">
                    Learn More 
                    <RiArrowRightSLine />
                  </Link>
                </Card>
              );
            })
          }
        </div>
      </div>
    </section>
  );
}

export default Courses;