import { Link } from "react-router-dom";

const PrimaryButton = ({ path, name, target, rel }) => {
    return (
        <Link
            to={path}
            target={target}
            rel={rel}
            className="btn lg">
            {name}
        </Link>
    );
}

export default PrimaryButton;