import CourseButton from "./CourseButton";

const CourseCard = ({ id, image, title, description, enrolled, price, startDate }) => {
    return (
        <div className="course__card">
                <div className="course__card-imgBx">
                    <img src={`${process.env.PUBLIC_URL}/images/courses/course/${image}`} alt={image} />
                </div>
                <div className="course__card-content">
                    <div className="course__card-details">
                        <h2>{title}<br />
                            <span>{description}</span>
                        </h2>
                        <div className="course__card-data">
                            <h3>{enrolled}
                                <br />
                                <span>Already Enrolled</span>
                            </h3>
                            <h3>{price}
                                <br />
                                <span>Price</span>
                            </h3>
                            <h3>{startDate}<br />
                                <span>Start Date</span>
                            </h3>
                        </div>
                        <CourseButton
                            path={"/courses/" + id}
                            name="Details"
                        />
                    </div>
                </div>
            </div>
    );
}

export default CourseCard;