import Image from '../../assets/home/values.png';
import SectionHead from './SectionHead';
import { GiCutDiamond } from 'react-icons/gi';
import Card from '../../ui/Card';
import { values } from '../../data/home/values';


const Values = () => {
    return (
        <section className='values'>
            <div className="container values__container">
                <div className="values__left">
                    <div className="values__image">
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img src={Image} alt="Values Image" />
                    </div>
                </div>
                <div className="values__right">
                    <SectionHead icon={<GiCutDiamond />} title="Why Choose Us?" />
                    <p>
                        We empower individuals to educate through Accessibility, Engagement, Practical Experience, and Expertise.
                    </p>
                    <div className="values__wrapper">
                        {
                            values.map(({ id, icon, title, desc }) => {
                                return (
                                    <Card key={id} className="values__value">
                                        <span>{icon}</span>
                                        <h4>{title}</h4>
                                        <small>{desc}</small>
                                    </Card>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Values;