import Header from "../../../components/common/Header";
import { Link, useParams } from "react-router-dom";
import { courses } from "../../../data/courses/courses";
import { BsCheckAll } from 'react-icons/bs';
import './course-details.css';
import PrimaryButton from "../../../components/common/PrimaryButton";
import InfoBanner from "../../../components/common/banner/InfoBanner";
import Strikethrough from "../../../utils/Strikethrough";

const CourseDetails = () => {
    const { id } = useParams();
    const courseId = parseInt(id, 10);
    const data = courses.filter(course => course.id === courseId);
    const courseItem = data[0];
    const bannerInfo = courseItem.discount.percent + "% discount is going on for this course till " + courseItem.discount.till + ".";
    const headerImage = `${process.env.PUBLIC_URL}/images/courses/course/${courseItem.image}`;

    return (
        <>
            <Header title={courseItem.title} image={headerImage}>
                {courseItem.shortDescription}
            </Header>

            <InfoBanner
                path={courseItem.enrollmentForm}
                linkedText="Register Now!"
                infoText={bannerInfo}
                target="_blank"
            />

            <div className="course__details-container">
                <h2>{courseItem.title}</h2>
                <p>{courseItem.longDescription}</p>
                <h3>Duration</h3>
                <p>This course is {courseItem.durationInMonths} months long with {courseItem.sessionsPerWeek} sessions per week, lasting {courseItem.hoursPerSession} hours each. A total of approximately {courseItem.durationInSessions} sessions or {courseItem.durationInHours} hours of instruction.</p>
                <h3>Prerequisites</h3>
                {
                    courseItem.prerequisites == null ?
                        <p>No prior experience with programming is required. All you need is a computer and an internet connection to participate in this course.</p> :
                        <Link to={"/courses/" + courseItem.prerequisites.id} className='link'>
                            {courseItem.prerequisites.name}
                        </Link>
                }
                <h3>Cost</h3>
                <p><Strikethrough>{courseItem.price} BDT</Strikethrough><h4>{courseItem.discount.discounted_price} BDT</h4></p>
                <h3>Start Date</h3>
                <p>{courseItem.startDate}</p>
                <h3>Enrolment Deadline</h3>
                <p>{courseItem.deadline}</p>

                <h2>Topics Covered</h2>
                <ul>
                    {
                        courseItem.topicsCovered.map(topic =>
                            <li>
                                <BsCheckAll className="course__details-checkbox" />
                                <span>{topic}</span>
                            </li>
                        )
                    }
                </ul>

                <div className="course__details-button">
                    <PrimaryButton
                        path={courseItem.enrollmentForm}
                        target='_blank'
                        rel='noreferrer noopener'
                        name="Get Enrolled"
                    />
                </div>

            </div>
        </>

    );
}

export default CourseDetails;