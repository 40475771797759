import HeaderImage from '../../assets/book-online/book-online-header.png';
import Header from '../../components/common/Header';
import { InlineWidget } from 'react-calendly';

const BookOnline = () => {
    return (
        <>
            <Header title="Book a Session" image={HeaderImage}>
                Book an appointment to discuss your learning goals and explore our courses with one of our knowledgeable teachers. Get a better understanding of Codebuddies and take the first step towards confident coding.
            </Header>

            <div className="calendly">
                <InlineWidget
                    url='https://calendly.com/codebuddies/30min'
                    styles={{
                        height: '80vh',
                    }}
                    pageSettings={{
                        backgroundColor: '0a0c24',
                        hideEventTypeDetails: false,
                        hideLandingPageDetails: false,
                        primaryColor: 'ffb116',
                        textColor: 'e9e9f7'
                    }}
                />
            </div>
        </>
    );
}

export default BookOnline;