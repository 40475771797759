import React, { useEffect } from 'react';
import InputComponent from '../common/input-component/InputComponent';

const ContactForm = ({ form, sendEmail, formTitle, fields }) => {

    useEffect(() => {
        const inputs = document.querySelectorAll('.input');

        function focusFunc() {
            let parent = this.parentNode;
            parent.classList.add('focus');
        }

        function blurFunc() {
            let parent = this.parentNode;
            if (this.value === "") {
                parent.classList.remove('focus');
            }
        }

        inputs.forEach((input) => {
            input.addEventListener('focus', focusFunc);
            input.addEventListener('blur', blurFunc);

            return () => {
                input.removeEventListener('focus', focusFunc);
            };
        });
    }, []);

    return (
        <div className="contact__form">
            <form ref={form} onSubmit={sendEmail}>
                <h3 className="title">{formTitle}</h3>
                {
                    fields.map(({ id, name, span, emailjsVar, type, required }) => {
                        return <InputComponent 
                            key={id}
                            name={name}
                            span={span}
                            emailjsVar={emailjsVar}
                            type={type}
                            required={required}
                        />
                    })
                }
                <input type="submit" value="Submit" className="submit__btn" />
            </form>
        </div>
    );
}

export default ContactForm;