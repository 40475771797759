import Image from '../../assets/home/main-header.png';
import PrimaryButton from "../common/PrimaryButton";
import InfoBanner from '../common/banner/InfoBanner';

const MainHeader = () => {
    return (
        <header className="main__header">
            <div className="home__banner">
            <InfoBanner
                path="/#/courses"
                linkedText="Register Now!"
                infoText="20% discount is going on for all the courses."
                target={null}
            />
            </div>
            <div className="container main__header-container">
                <div className="main__header-left">
                    <h4>#CodeWithConfidence</h4>
                    <h1>Unlock Your Coding Potential with Codebuddies</h1>
                    <p>
                        Empowering the next generation of coders with comprehensive programming courses, taught by expert teachers!
                    </p>
                    <PrimaryButton path='/courses' name='Get Started' />
                </div>
                <div className="main__header-right">
                    <div className="main__header-image">
                        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
                        <img src={Image} alt="Main Header Image" />
                    </div>
                </div>
            </div>
        </header>
    );
}

export default MainHeader;