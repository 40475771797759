import HeaderImage from '../../assets/faq/faq-header.jpg';
import Header from '../../components/common/Header';
import FAQList from '../../components/faq/FAQList';
import { faqs } from '../../data/home/faqs';
import './faq.css';

const FAQ = () => {
    return (
        <>
            <Header title="Clear Your Doubts" image={HeaderImage}>
                Get answers to all your questions about Codebuddies. Browse through our comprehensive list of frequently asked questions and find clarity on all your concerns.
            </Header>

            <section className="faqs">
                <div className="container faqs__container">
                    <FAQList faqs={faqs} />
                </div>
            </section>
        </>
    );
}

export default FAQ;