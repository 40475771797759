
export const teachers = [
    {
        id: 1,
        name: "Amit Sen",
        image: "amit-sen.png",
        occupation: [
            {
                occupation_id: 1,
                designation: "Senior Software Engineer",
                company: "Lotto24 AG"
            },
            {
                occupation_id: 2,
                designation: "Lecturer",
                company: "SRH Hochschule Berlin"
            }
        ],
        description: "Amit Sen is a problem-solving enthusiast with over 12 years of experience in the software industry. He has worked with various programming languages, including C, Java, Python, Kotlin (Android), and Swift (iOS), making him a versatile instructor for students looking to learn coding.",
        email: "amit.sen@codebuddies.net",
        linkedIn: "https://www.linkedin.com/in/amit-sen-bb156428/",
        twitter: "https://twitter.com/codeplayer09"
    }
]