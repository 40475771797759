import { Link } from 'react-router-dom';
import './not-found.css';

const NotFound = ({ pageTitle }) => {
    return (
        <section>
            <div className="container notfound__container">
                <h2>{pageTitle}</h2>
                <Link to="/" className='btn'>Go Back Home</Link>
            </div>
        </section>
    );
}

export default NotFound;