import { useNavigate } from "react-router-dom";

const CourseButton = ({ path, name }) => {

    let navigate = useNavigate();
    const routeChange = () => {
        navigate(path);
    }

    return (
        <div className="course__card-btn">
            <button onClick={routeChange}>{name}</button>
        </div>
    );
}

export default CourseButton;