
export const aboutUsData = [
    {
        id: 1,
        title: "Our Story",
        image: "about-us-story.png",
        data: [
            {
                id: 1,
                text: "Codebuddies is the result of a passion for programming and a desire to make the learning experience accessible to everyone. Our founders, who are established developers themselves in the industry, saw the gap in the market and set out to create a platform that was both engaging and affordable. They envisioned a place where anyone could come to learn programming and get a taste of what it was like to be a developer. With expertise in software development and a desire to give back, the team came together to create Codebuddies."
            },
            {
                id: 2,
                text: "The platform will offer live, online classes in popular programming languages and provide individuals with the skills and confidence they need to succeed in the industry. With comprehensive and hands-on courses taught by experienced professionals, Codebuddies is poised to become a leading provider of programming education."
            },
            {
                id: 3,
                text: "The idea behind Codebuddies is simple: provide live, online classes in popular programming languages and equip individuals with the necessary skills and confidence to succeed in the industry. The platform has started small, with just a few courses in C and Java, but it's quickly going to offer more courses in some other important programming languages, such as Python, Swift(iOS), Kotlin(Android) and so on, as more and more individuals find success in their coding journeys through Codebuddies."
            },
            {
                id: 4,
                text: "Though Codebuddies is still in its early stages, the team is driven by the mission to help individuals learn programming and gain confidence in coding. They are excited to bring the platform to the world and to see the positive impact it will have on the lives of individuals everywhere."
            }
        ],
        sectionClass: "about__story",
        containerClass: "about__story-container",
        imageOnRight: false
    },

    {
        id: 2,
        title: "Our Vision",
    image: "about-us-vision.png",
    data: [
        {
            id: 1,
            text: "At Codebuddies, our vision is to be the leading online platform for learning programming and coding. We strive to provide a supportive and inclusive community where anyone can learn, grow, and connect with others who share their passion for coding. Our mission is to help people of all ages, backgrounds, and experience levels to discover the joy and satisfaction of building digital products and shaping the future."
        },
        {
            id: 2,
            text: "We believe that everyone has the potential to learn how to code and to achieve their goals through technology. That's why we are committed to providing the highest quality educational resources, tools, and support to help our users unlock their full potential. Whether you're a beginner who wants to learn coding for the first time or an experienced programmer looking to expand your skills, Codebuddies has everything you need to succeed."
        },
        {
            id: 3,
            text: "Our vision is to empower a new generation of coders and to change the face of tech education. We believe that everyone should have the opportunity to learn how to code and to make an impact in the world through technology. By working together, we can make coding accessible to everyone and help people to achieve their full potential."
        }
    ],
    sectionClass: "about__vision",
        containerClass: "about__vision-container",
        imageOnRight: true
    },

    {
        id: 3,
        title: "Our Mission",
        image: "about-us-mission.png",
        data: [
            {
                id: 1,
                text: "The mission of Codebuddies is to create a supportive and inclusive community of learners and educators who are passionate about programming."
            },
            {
                id: 2,
                text: "At Codebuddies, we believe that everyone should have access to quality education and resources to help them learn and grow. That's why we offer live, online classes that are accessible to people from all walks of life, no matter where they are in the world. Our classes are taught by experienced educators who are passionate about their subject matter and dedicated to helping students succeed."
            },
            {
                id: 3,
                text: "We believe that learning to code should be a fun and engaging experience, and that's why we have designed our platform with this in mind. Our classes are designed to be interactive and hands-on, with plenty of opportunities for students to ask questions, collaborate with their peers, and get instant feedback from their instructors."
            },
            {
                id: 4,
                text: "At Codebuddies, our goal is to help students \"Code with Confidence\". We believe that confidence comes from understanding and mastery, and that's why we are dedicated to providing our students with the knowledge, skills, and support they need to succeed. Whether you're a beginner who's just starting out or an experienced programmer looking to expand your skills, we are here to help."
            }
        ],
        sectionClass: "about__mission",
        containerClass: "about__mission-container",
        imageOnRight: false
    }
]