import { TfiEmail } from 'react-icons/tfi';
import { BsLinkedin } from 'react-icons/bs';
import { RxTwitterLogo } from 'react-icons/rx';

const TeacherCard = ({ image, name, occupation, description, email, linkedIn, twitter}) => {
    return (
        <div className="container">
            <div className="avatar">
                <img src={`${process.env.PUBLIC_URL}/images/teachers/${image}`} alt={image} />
            </div>
            <div className="content">
                <h1>{name}</h1>
                {
                    occupation.map(({ occupation_id, designation, company }) => {
                        return (
                            <h3 key={occupation_id}>{designation + " @ " + company}</h3>
                        );
                    })
                }
                <p>{description}</p>
            </div>
            <div className="social">
                <a href={"mailto:" + email} target="_blank" rel='noreferrer noopener'><TfiEmail /></a>
                <a href={linkedIn} target="_blank" rel="noreferrer noopener">
                    <BsLinkedin />
                </a>
                <a href={twitter} target="_blank" rel="noreferrer noopener">
                    <RxTwitterLogo />
                </a>
            </div>
        </div>
    );
}

export default TeacherCard;