import Header from "../../components/common/Header";
import HeaderImage from '../../assets/courses/courses-header.jpg';
import CourseCard from "../../components/courses/CourseCard";
import { courses } from "../../data/courses/courses";
import './courses.css';

/**
 * This is the actual Courses route/page.
 */
const Courses = () => {
    return (
        <>
            <Header title="Explore Our Courses" image={HeaderImage}>
                Discover a world of programming knowledge with our comprehensive courses. Learn the much needed programming languages and technologies, and take your skills to the next level.
            </Header>
            <div className="course__card-wrapper">
                <div className="course__list-container">
                    {
                        courses.map(({ id, title, description, image, enrolled, price, startDate }) => {
                            return (
                                <CourseCard key={id}
                                    id={id}
                                    title={title}
                                    description={description}
                                    image={image}
                                    enrolled={enrolled}
                                    price={price}
                                    startDate={startDate}
                                />
                            );
                        })
                    }
                </div>
            </div>
        </>
    );
}

export default Courses;