import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { links } from '../../data/links';
import { GoThreeBars } from 'react-icons/go';
import { MdOutlineClose } from 'react-icons/md'
import Logo from '../../assets/navbar/nav-logo.png';
import './navbar.css';

const Navbar = () => {

  const [isNavShowing, setIsNavShowing] = useState(false);

  return (
    <nav>
      <div className="container nav__container">
        {/* Logo */}
        <Link className='logo' to='/'
          onClick={() =>
            setIsNavShowing(false)
          }>
          <img src={Logo} alt="Nav Logo" />
        </Link>

        {/* nav links */}
        <ul className={`nav__links ${isNavShowing ? 'show__nav' : 'hide__nav'}`}>
          {
            links.map(({ id, name, path }, index) => {
              return (
                <li key={id}>
                  <NavLink to={path}
                    className={
                      ({ isActive }) =>
                        isActive ? 'active-nav' : ''
                    } onClick={() =>
                      setIsNavShowing(prev => !prev)
                    } >
                    {name}
                  </NavLink>
                </li>
              );
            })
          }
        </ul>

        {/* nav buttons */}
        <button className='nav__toggle-btn'
          onClick={() =>
            setIsNavShowing(prev => !prev)
          }>
          {
            isNavShowing ? <MdOutlineClose /> : <GoThreeBars />
          }
        </button>
      </div>
    </nav>
  );
}

export default Navbar;