
export const courses = [
    {
        id: 1,
        author: 'Amit Sen',
        title: "C Fundamentals for Beginners",
        shortDescription: "Learn the basics of C programming with our beginner-friendly course.",
        price: 15999,
        enrolled: "21",
        startDate: "01.05.2023",
        image: "c-1.png",
        longDescription: "This course is a comprehensive course designed for individuals with no prior programming experience. The course covers the basics of the C programming language, including data types, variables, operators, control structures, functions, arrays, pointers, and more. By the end of the course, students will have a solid understanding of C programming and be able to write simple programs with confidence. Whether you're looking to start a career in programming or just want to learn a new skill, this course is the perfect starting point.",
        durationInMonths: 3,
        durationInSessions: 25,
        durationInHours: 50,
        sessionsPerWeek: 3,
        hoursPerSession: 2,
        prerequisites: null,
        topicsCovered: [
            "Introduction to C and its history",
            "Data types and variables",
            "Operators and expressions",
            "Input and output operations",
            "Decision making statements (if-else, switch)",
            "Loops (for, while, do-while)",
            "Arrays and strings",
            "Functions and pointers",
            "Basic Structures and unions",
            "File handling in C"
        ],
        enrollmentForm: "https://docs.google.com/forms/d/1kCnb4fr1vk-OvcgffY5eKRq7KjX9LhWIS5yzZCODBXg",
        deadline: "15.04.2023",
        discount: {
            percent: 20,
            till: "31.03.2023",
            discounted_price: 12799
        }
    },
    {
        id: 2,
        author: 'Amit Sen',
        title: "Mastering Java: A Guide for Beginners",
        shortDescription: "Discover the power of Java through this course, designed specifically for beginners.",
        price: 18999,
        enrolled: "19",
        startDate: "01.05.2023",
        image: "java-1.jpeg",
        longDescription: "It's a comprehensive course that introduces you to the world of Java programming from scratch. It covers the fundamental concepts of Java such as syntax, data types, operators, decision making statements, loops, arrays, strings, and more. Additionally, you'll learn about the history of Java, the JVM, JDK, and JRE, as well as compilers and interpreters. This course also delves into the principles and concepts of object-oriented programming (OOP), providing you with a solid foundation for future development. By the end of this course, you'll have a strong understanding of the basics of Java programming and the ability to develop your own simple applications.",
        durationInMonths: 3,
        durationInSessions: 25,
        durationInHours: 50,
        sessionsPerWeek: 3,
        hoursPerSession: 2,
        prerequisites: null,
        topicsCovered: [
            "History of Java",
            "JVM, JDK and JRE",
            "Compilers and Interpreters",
            "Java syntax and control structures",
            "Data types and variables",
            "Operators and expressions",
            "Decision making statements (if-else, switch)",
            "Loops and Arrays",
            "String Operations",
            "Recursion",
            "Object-oriented programming (OOP) concepts and principles"
        ],
        enrollmentForm: "https://docs.google.com/forms/d/1-Cg2KCStY7lNMIlezoxxE_oFpZJzrL0GdMJFjU5IA7U",
        deadline: "15.04.2023",
        discount: {
            percent: 20,
            till: "31.03.2023",
            discounted_price: 15199
        }
    },
    {
        id: 3,
        author: 'Mark Anthony',
        title: "C Programming: Advanced Techniques and Concepts",
        shortDescription: "Unlock your coding potential with our advanced C programming course.",
        price: 18999,
        enrolled: "13",
        startDate: "01.08.2023",
        image: "c-2.jpeg",
        longDescription: "This course is designed for experienced C programmers who want to further enhance their skills and master advanced techniques and concepts in C programming. The course covers topics such as advanced data types, dynamic memory allocation, data structures, recursion, string operations, bit manipulation, preprocessor directives, and more. By the end of the course, students will have a deep understanding of the intricacies of the C language and will be able to write efficient and optimized programs.",
        durationInMonths: 3.5,
        durationInSessions: 30,
        durationInHours: 60,
        sessionsPerWeek: 3,
        hoursPerSession: 2,
        prerequisites: {
            name: "C Fundamentals for Beginners",
            id: 1
        },
        topicsCovered: [
            "Advanced data types: structures, unions, and enumerations",
            "Complex Pointer Artihmetic",
            "Dynamic memory allocation",
            "Data Structures: Linked Lists, Stacks and Queues",
            "Recursion",
            "String Operations",
            "Bit manipulation and bitwise operators",
            "Preprocessor directives and macros",
            "Debugging and error handling",
            "Custom Header Files",
            "C Graphics"
        ],
        enrollmentForm: "https://docs.google.com/forms/d/1IrN2NWIrmxn0eoTPdwdZlF8hKMJ9qw0u6xc4lt_jUbY",
        deadline: "15.07.2023",
        discount: {
            percent: 20,
            till: "31.05.2023",
            discounted_price: 15199
        }
    },
    {
        id: 4,
        author: 'Mark Anthony',
        title: "Java Mastery: Advanced Techniques & Best Practices",
        shortDescription: "Take your Java skills to the next level with our advanced course in Java Mastery.",
        price: 22999,
        enrolled: "26",
        startDate: "01.08.2023",
        image: "java-2.jpeg",
        longDescription: "Take your Java skills to the next level with this advanced course that covers essential topics for building high-quality software. You'll learn about object-oriented programming principles, exception handling, collections, concurrency, functional programming with lambdas and streams, and more. By the end of the course, you'll have a solid understanding of Java best practices and be able to write efficient and maintainable code with ease.",
        durationInMonths: 3.5,
        durationInSessions: 30,
        durationInHours: 60,
        sessionsPerWeek: 3,
        hoursPerSession: 2,
        prerequisites: {
            name: "Mastering Java: A Guide for Beginners",
            id: 2
        },
        topicsCovered: [
            "Java classes and objects",
            "Encapsulation and abstraction",
            "Inheritance and polymorphism",
            "Interface and Abstract class",
            "Exception handling",
            "Collections and Generics",
            "File I/O",
            "Concurrency and Threading",
            "Functional programming in Java using lambda expressions and streams",
            "Java Swing",
            "Basic database connectivity with JDBC"
        ],
        enrollmentForm: "https://docs.google.com/forms/d/12OOx1jMUKfCGZkMY39qs9jNeKk2bgxgk66sdKkNagpU",
        deadline: "15.07.2023",
        discount: {
            percent: 20,
            till: "31.05.2023",
            discounted_price: 18399
        }
    }
]