import { GrInstagram } from "react-icons/gr";
import { 
    FaLinkedin, 
    FaFacebookSquare, 
    FaTwitterSquare
} from "react-icons/fa";
import './socials.css';

const Socials = () => {
    return (
        <div className="socials">
            <a>
                <FaLinkedin />
            </a>
            <a href="https://www.facebook.com/codebuddies.net/" target="_blank" rel="noreferrer noopener">
                <FaFacebookSquare />
            </a>
            <a>
                <FaTwitterSquare />
            </a>
            <a href="https://www.instagram.com/code__buddies/" target="_blank" rel="noreferrer noopener">
                <GrInstagram />
            </a>
        </div>
    );
}

export default Socials;