import Header from '../../components/common/Header';
import HeaderImage from '../../assets/about-us/about-us-header.jpg';
import { aboutUsData } from '../../data/about-us/about-us';
import AboutUsSection from '../../components/about-us/AboutUsSection';
import './about-us.css';

const AboutUs = () => {
    return (
        <>
            <Header title="About Us" image={HeaderImage}>
                Empowering the world, one code at a time: Discover the story, vision, and mission of Codebuddies.
            </Header>

            {
                aboutUsData.map(({ id, title, image, data, sectionClass, containerClass, imageOnRight }) => {
                    return <AboutUsSection key={id}
                        sectionClass={sectionClass}
                        containerClass={"container " + containerClass}
                        title={title}
                        image={image}
                        data={data}
                        imageOnRight={imageOnRight}
                    />
                })
            }
        </>
    );
}

export default AboutUs;