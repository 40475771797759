import FAQComponent from "./FAQComponent";

const FAQList = ({ faqs }) => {
    return (
        <div className="faqs__wrapper">
            {
                faqs.map(({ id, question, answer }) => {
                    return (
                        <FAQComponent key={id} question={question} answer={answer} />
                    );
                })
            }
        </div>
    );
}

export default FAQList;