const InputComponent = ({ name, span, emailjsVar, type, required }) => {
    return (
        <>
            {
                type === "textarea" ?
                    <div className="input__container textarea">
                        <textarea required={required} name={emailjsVar} className="input"></textarea>
                        <label>{name}</label>
                        <span>{span}</span>
                    </div> :
                    <div className="input__container">
                        <input required={required} type={type} name={emailjsVar} className="input" />
                        <label>{name}</label>
                        <span>{span}</span>
                    </div>
            }
        </>
    );
}

export default InputComponent;